.root {
}

.hero {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
  box-shadow: 0px -1px 4px 0px #b3b3b3 inset;
  margin-bottom: 40px;
  @media screen and (max-width: 500px) {
    height: 200px;
    margin-bottom: 20px;
  }
  &Title {
    font-size: 30px;
    font-weight: 700;
    color: #eeeeee;
    line-height: 45px;
    position: absolute;
    bottom: 10%;
    left: 10%;
    background-color: rgba(0, 159, 227, 0.8);
    border: 4px solid #009fe3;
    max-width: 50%;
    padding: 20px;
    @media screen and (max-width: 500px) {
      font-size: 24px;
      line-height: 30px;
      max-width: 80%;
    }
  }
}
.contentWrap {
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.content {
  margin-bottom: 40px;
}
.formWrap{
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {

  &Slide {
    width: 600px;
    height: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    @media screen and (max-width: 800px) {
      width: 600px;
      height: 600px;
    }
    @media screen and (max-width: 600px) {
      width: 400px;
      height: 400px;
    }
    @media screen and (max-width: 400px) {
      width: 250px;
      height: 250px;
    }
  }
}

.root {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  transition: 0.3s;
  &.dark {
    border: 2px solid #eee;
    color: #eee;
    text-transform: uppercase;
    &:hover {
      background: #eeeeee;
      color: #222831;
    }
  }
  &.light {
    border: 2px solid #222831;
    color: #222831;
    text-transform: uppercase;
    &:hover {
      background: #222831;
      color: #eeeeee;
    }
  }
  &.accent {
    border: 2px solid #f46a5a;
    background: #f46a5a;
    color: #eee;
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      background: #eeeeee;
      color: #f46a5a !important;
    }
  }
}

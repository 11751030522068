.contentWrap {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.h2,
.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.reviewList {
}
.reviewItem {
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #009fe3;
}
.reviewItemWrap {
  position: relative;
  .rating {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #009fe3;
  }
  .name {
    margin-bottom: 10px;
  }
  .serviceName {
    color: #b3b3b3;
    font-size: 14px;
  }
  .date {
    margin-bottom: 10px;
    color: #b3b3b3;
    font-size: 14px;
  }
  .text {
    margin-bottom: 10px;
  }
}

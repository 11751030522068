.root {
  padding: 20px;
  width: 400px;
  position: relative;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.form {
  width: 100%;
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: space-between;
}
.textLabel {
  display: block;
  margin-bottom: 10px;
  &Name {
    margin-bottom: 4px;
  }
}
.textInput {
  border: 1px solid #009fe3;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.selectLabel {
  display: block;
  margin-bottom: 10px;
  &Name {
    margin-bottom: 4px;
  }
}
.select {
  border: 1px solid #009fe3;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.textareaLabel {
  display: block;
  margin-bottom: 10px;
  &Name {
    margin-bottom: 4px;
  }
}
.textarea {
  border: 1px solid #009fe3;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  height: 120px;
  resize: vertical;
  padding: 10px;
}
.submitBtn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  //height: 40px;
  padding: 8px 10px;
  font-size: 16px;
  transition: 0.3s;
  border: 2px solid #009fe3;
  color: #009fe3;
  text-transform: uppercase;
  &:hover {
    background: #eeeeee;
    color: #222831;
  }
}
.hidden{
  display: none;
}

.modal{
  &Root{
    inset: initial;
    background-color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px -2px #000;

  }
  &Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  &Content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  &Message{
    font-weight: 700;
    font-size: 20px ;
  }
}

.privacyAgreeText{
  font-size: 10px;
  line-height: 12px;
  margin-top: 10px;
}
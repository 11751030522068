.header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  //height: 120px;
  border-bottom: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #b3b3b3;
  width: 100%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  background: #009fe3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media (max-width: 900px) {
    position: relative;
    width: 100%;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &Left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    @media (max-width: 560px) {
      margin-bottom: 10px;
    }
  }
  &Right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
    @media (max-width: 900px) {
    }
    .order_btn {
    }
  }
}

.bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  padding: 20px;
  width: 100%;
  @media (max-width: 900px) {
    margin-top: 0;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
}
.logo {
  flex-grow: 0;
  width: 200px;
  min-width: 100px;
  margin-right: 20px;
  &Icon {
    width: 100%;
    height: auto;
  }
}

.contactLink {
  display: flex;
  align-items: center;

  &.mail {
    //&.desktop{
    //  @media (min-width: 790px) {
    //    display: none;
    //  }
    //}
    //&.tablet{
    //  @media (max-width: 790px) {
    //    display: none;
    //  }
    //
    //}
    //&.mobile{
    //  @media (max-width: 560px) {
    //    display: none;
    //  }
    //}
  }
  &.phone {
    @media (max-width: 560px) {
      .text{
        font-size: 12px;
      }
    }
  }
  &.telegram {
  }
  &.whatsapp {
  }

  .icon {
    width: 40px;
    height: auto;
    fill: #eeeeee;
    &.telegram {
      box-shadow: 0 0 0px 2px #eee;
      border-radius: 50%;
      width: 36px;
    }
    &.whatsapp {
      width: 46px;
    }
    &.youtube {
      width: 44px;
    }
  }
  .text {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #eeeeee;
  }
  @media (max-width: 900px) {
    .icon {
      //width: 20px;
    }
    .text {
      font-size: 16px;
    }
  }
  @media (max-width: 560px) {
    .icon {
      &.telegram {
        width: 16px;
      }
      &.whatsapp {
        width: 24px;
      }
      &.youtube {
        width: 22px;
      }
      &.dzen {
        width: 22px;
      }
      &.phone {
        width: 20px;
      }
    }
  }
}

.burgerBtn{
  min-width: 20px;
  height: 40px;
}

.modal{
  &Root{
    inset: initial;
    background-color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px -2px #000;
  }
  &Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  &Content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  &Message{
    font-weight: 700;
    font-size: 20px ;
  }
  &CloseBtn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
  }
}

.headMail{
  display: none;
  @media screen and (min-width: 790px) {
    display: block;
  }
}
.headPhone{
  display: block;
  @media screen and (min-width: 790px) {
    display: none;
  }
}

.contactLinkGroup{
  display: flex;
  gap: 10px;
}
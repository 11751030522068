.root {
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;
}

.h2,
.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
}

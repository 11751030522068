.root {
  padding-left: 20px;
  padding-right: 20px;
}
.paragraph {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.list {
  margin-left: 20px;
  margin-bottom: 20px;
  &Header {
    margin-left: 20px;
    margin-bottom: 10px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
.bold{
  font-weight: 700;
}

.root{

}

.contentWrap{
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.h2,
.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.text {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.slider{
  height: 400px;
  width: 100%;
  margin-bottom: 40px;
  border-bottom: 1px solid #b3b3b3;
  box-shadow: 0px 1px 4px 0px #b3b3b3;
  &Slide{
    background-size: cover;
    background-position: center;
    min-height: 400px;
  }
}
.root {
}
.hidden {
  display: none;
}

.contentWrap {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.text {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.prices {
  max-width: 1000px;
  margin: 0 auto;
}
.pricesHeader {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}
.tableWrap {
}
.table {
  border: 2px solid #009fe3;
  width: 100%;
  margin-bottom: 40px;
}
.tHeadRow {
}
.tHeadCell,
.tBodyCell {
  padding: 10px;
  border: 2px solid #009fe3;
}
.tBodyCellPrice{
  padding: 10px;
  border: 2px solid #009fe3;
  white-space: nowrap;
}
.tBodyRow {
}
.tBodyCell {
}

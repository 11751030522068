.root {
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.img {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}

.title {
  width: 100%;
  font-size: 20px;
  padding: 10px 30px 10px;
  height: 300px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: rgba(0, 159, 227, 0.8);
  color: #fff;
  position: absolute;
  bottom: -200px;
  transition: 0.2s ease-in;
  box-shadow: 0 2px 6px -2px #222831;
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.service {
  width: 300px;
  height: 300px;
  overflow: hidden;
  box-shadow: 0 0px 4px 0 #009fe3;
  &:hover {
    box-shadow: 0px 2px 8px 0 #009fe3;
    transition: 0.3s ease-in;
    .wrap {
    }
    .img {
      transform: scale(1.1);
      transition: transform 0.5s ease-out;
    }
    .title {
      background-color: rgba(0, 159, 227, 0.8);
      bottom: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: 0.2s ease-out;
      //transition-delay: 0.2s;
    }
  }
}

.nav {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  column-gap: 10px;

  &List {
    display: flex;
    column-gap: 20px;
    @media (max-width: 1010px) {
      column-gap: 10px;
    }
  }
  &Item {
  }
  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #222831;
    padding: 0 15px;
    border: 2px solid transparent;
    border-radius: 20px;
    transition: 0.3s;

    &:hover,
    &.active {
      border: 2px solid #009fe3;
      box-shadow: 0px 1px 4px -1px 009fe3;
    }
    &.active {
      background: #009fe3;
      color: #eee;
    }
    @media (max-width: 400px) {
      padding: 0 10px;
      font-size: 16px;
    }
  }
  &Icon {
    width: 30px;
    height: auto;
  }
}

.contentWrap {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.root{}

.contacts{
  width: 50%;
}

.h2,
.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.text {
  font-size: 20px;
  line-height: 1.5;
}

.address{
  //margin-bottom: 20px;
  margin-top: 20px;
}

.contactLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .icon {
    width: 20px;
    height: auto;
    fill: #222831;
    margin-left: 10px;
    &.telegram {
      box-shadow: 0 0 0px 2px #eee;
      border-radius: 50%;
      width: 20px;
    }
    &.whatsapp {
      width: 26px;
    }

    @media (max-width: 580px) {
      width: 20px;
    }
  }
  .text {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #222831;
  }
}

.socials{

}
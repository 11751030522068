.footer{
  //height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #b3b3b3;
  box-shadow: 0px -1px 4px 0px #b3b3b3;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.logo{
  flex-grow: 0;
  width: 200px;
  min-width: 100px;
  margin-right: 20px;
  &Icon{
    width: 100%;
    height: auto;
  }
  @media (max-width: 780px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.contacts{

}
.address{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 580px) {
    flex-direction: column;
    gap: 10px;
  }
}

.orderBtn{
  min-width: 200px;
  @media (max-width: 560px) {
    width: 100%;
  }
}

.contactLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .icon {
    width: 40px;
    height: auto;
    fill: #222831;
    &.telegram {
      box-shadow: 0 0 0px 2px #eee;
      border-radius: 50%;
      width: 36px;
    }
    &.whatsapp {
      width: 46px;
    }

    @media (max-width: 580px) {
      //width: 20px;
    }
  }
  .text {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #222831;
  }
}

.socials{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 560px) {
    justify-content: center;
    gap: 10px;
  }
}
.copyright{
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(34, 40, 49, 0.5);
}

.modal{
  &Root{
    inset: initial;
    background-color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px -2px #000;
  }
  &Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  &Content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  &Message{
    font-weight: 700;
    font-size: 20px ;
  }
  &CloseBtn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
  }
}
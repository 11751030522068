.root {
}

.contentWrap {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.h2,
.h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  color: #222831;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.text {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.equipment {
  &Wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 500px) {
      gap: 10px;
    }
  }

  &Item {
    flex: 0 0 auto;
    width: 240px;
    height: 240px;
    padding: 10px;
    overflow: hidden;
    position: relative;
    border: 2px solid #009fe3;
    box-shadow: 0 0px 4px 0 #009fe3;
    display: flex;
    align-items: center;
    justify-content: center;


    @media screen and (max-width: 500px) {
      width: 170px;
      height: 170px;
    }
    @media screen and (max-width: 380px) {
      width: 160px;
      height: 160px;
    }
    &Img {
      width: 100%;
      height: auto;
    }

    &Info {
      opacity: 0;
      transform: translateY(100%);
      transition: 0.2s;
      background-color: rgba(0, 159, 227, 0.8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      @media screen and (max-width: 500px) {
        padding: 10px;
      }

      &Text {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          line-height: 16px;
        }
        &.title{
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 10px;
          @media screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
        &.bold{
          font-weight: 700;
        }
        &Link {
          text-decoration: underline;
        }
      }
    }
    &:hover,
    &:active {
      .equipmentItemInfo {
        opacity: 1;
        transform: translateY(0);
        transition: 0.2s;
        z-index: 1;
      }
    }
  }
}

.layoutRoot{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
}
.layoutContent{
  position: relative;
  min-height: calc(100vh - 220px);
}